import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/index.less'
// import './styles/vant-theme.less';
import Vant from 'vant'
import 'vant/lib/index.css'
import 'amfe-flexible'
import { i18n, vantLocales } from './lang'
import {  Carousel,CarouselItem,Input, Button, Form,FormItem,Pagination} from 'element-ui';
Vue.use(Vant)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Input)
Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Pagination)
Vue.config.productionTip = false
vantLocales(i18n.locale)
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
