// 进行多语言支持配置
import Vue from 'vue' // 引入Vue
import VueI18n from 'vue-i18n' // 引入国际化的插件包
import { Locale } from 'vant'
// import Vant from 'vant';
import 'vant/lib/index.css'
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US'
import zhCN from 'vant/es/locale/lang/zh-CN'
// Locale.use('en-US', enUS);
// 引入自定义中文包
import customZH from './zh'
import policyZH from './policy_zh'
// 引入自定义英文包
import customEN from './en'
import policyEN from './policy_en'
// localStorage.setItem("locale", 'zh');
Vue.use(VueI18n) // 全局注册国际化包
// Vue.use(Locale);
// Vue.use(Vant);
// 创建国际化插件的实例
const messages = {
  zh: {
    ...zhCN,
    ...customZH, // 将自定义中文包加入
    ...policyZH
  },
  en: {
    ...enUS,
    ...customEN,
    ...policyEN
  }

}

// export default i18n
const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'zh', // 设置默认语言
  messages: messages // 设置资源文件对象
})

// 更新vant组件库本身的语言变化，支持国际化
function vantLocales (lang) {
  if (lang === 'en') {
    Locale.use(lang, enUS)
  } else if (lang === 'zh') {
    Locale.use(lang, zhCN)
  }
}

export { i18n, vantLocales }
