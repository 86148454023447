import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path: '/',
    component: () => import('@/views/layout'),
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'homePage',
        component: () => import('@/views/home')
      },
      {
        path: '/white',
        name: 'whitePage',
        component: () => import('@/views/white')
      },
      {
        path: '/play',
        name: 'playPage',
        component: () => import('@/views/play')
      },
      {
        path: '/paper',
        name: 'paperPage',
        component: () => import('@/views/paper')
      },
      // {
      //   path: '/qa',
      //   name: 'qa',
      //   component: () => import('@/views/qa')
      // },
      // {
      //   path: '/video',
      //   name: 'video',
      //   component: () => import('@/views/video')
      // },
      // {
      //   path: '/my',
      //   name: 'my',
      //   component: () => import('@/views/my')
      // }
    ]
  }

]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  // 在每次路由切换前，强制更新组件
  window.scrollTo(0, 0);
  next();
});
export default router
